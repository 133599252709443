import React from "react"
import styled from "styled-components"
import {Link} from "gatsby";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import arrowImage from "../../resources/img/icons/arrow-left.svg";
import arrowImageActive from "../../resources/img/icons/arrow-left-active.svg";

const PaginationContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 24px;  
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Arrow = styled(Link)`
  border: 1px solid ${colors.black};
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url(${arrowImage}) no-repeat;
  background-position: 50%;
  
  &:hover {
    border-color: ${colors.green};
    background: url(${arrowImageActive}) no-repeat ${colors.green};
    background-position: 50%;
  }
`;

const ArrowPrev = styled(Arrow)`
`;

const ArrowNext = styled(Arrow)`
  transform: rotate(180deg);
`;

const ArrowContainer = styled.div`
  padding: 20px;
  display: inline-flex;
`;

const PageLink = styled(Link)`
  padding: 20px;
  text-decoration: none;
  color: ${colors.black};
  ${props => props.active === "true" ? fonts.montserratBold : fonts.montserratRegular};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Pagination = ({pagesCount, current}) => {
  const currentPage = current * 1;

  let pagesVisible = [];

  const maxPagesVisible = Math.min(3, pagesCount);

  if (currentPage === 1) {
    pagesVisible.push(currentPage);

    let cursor = currentPage;

    while (pagesVisible.length < maxPagesVisible) {
      cursor++;

      pagesVisible.push(cursor);
    }
  } else if (currentPage === pagesCount && pagesCount >= 3) {
    pagesVisible.push(currentPage);

    let cursor = currentPage;

    while (pagesVisible.length < maxPagesVisible) {
      cursor--;

      pagesVisible.push(cursor);
    }
  } else {
    const previousPage = currentPage - 1;
    const nextPage = currentPage + 1;

    if (previousPage > 0) {
      pagesVisible.push(previousPage);
    }

    pagesVisible.push(currentPage);

    if (nextPage <= pagesCount) {
      pagesVisible.push(nextPage);
    }
  }

  pagesVisible.sort(function (a, b) {
    return a - b;
  });

  let prev = currentPage - 1;
  let next = currentPage + 1;

  return (
    <PaginationContainer>
      {prev > 0 &&
      <ArrowContainer>
        <ArrowPrev to={`/?page=${prev}`}>
        </ArrowPrev>
      </ArrowContainer>}
      {pagesVisible.map((page, index) => {
        return (
          <PageLink
            key={index}
            to={`/?page=${page}`}
            active={currentPage === page ? "true" : "false"}>
            {page}
          </PageLink>
        )
      })}
      {next <= pagesCount &&
      <ArrowContainer>
        <ArrowNext to={`/?page=${next}`}>
        </ArrowNext>
      </ArrowContainer>}
    </PaginationContainer>
  );
};

export default Pagination;
