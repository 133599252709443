import React, {useEffect, useState, useCallback} from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Core/Seo";
import PrivateRoute from "../router/PrivateRoute";
import dashboard from "../backend/dashboard";
import PageData from "../components/Core/PageData";
import Summary from "../components/Dashboard/Summary";
import Login from "../components/Github/Login";
import github from "../backend/github";
import Deployments from "../components/Dashboard/Deployments";
import Pagination from "../components/Dashboard/Pagination";
import fonts from "../styles/fonts";
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";

const StyledTitle = styled.h1`
  ${fonts.montserratRegular};
  
  @media(min-width: ${breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const IndexPage = ({location}) => {
  const isCancelled = React.useRef(false);
  const [pageData, setPageData] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const path = location.pathname;
  let pollingCount = 0;

  const subscribe = useCallback(async () => {
    const pageNumber = getPageNumber();

    pollingCount++;

    if (pollingCount >= 100) {
      return;
    }

    if (pageNumber !== 1) {
      return;
    }

    dashboard.subscribe()
      .then(async ({data}) => {
        const pageNumber = getPageNumber();

        if (pageNumber !== 1 || isCancelled.current) {
          return;
        }

        setPageData(data);

        await new Promise(resolve => setTimeout(resolve, 1000));
        await subscribe();
      })
      .catch(async () => {
        await subscribe();
      });
  }, [pollingCount]);

  const getDashboard = useCallback(() => {
    if (isCancelled.current) {
      return;
    }

    // setPageData(null);

    dashboard.getDashboard(getPageNumber())
      .then(async ({data}) => {
        if (isCancelled.current) {
          return;
        }

        setPageData(data);
      })
      .catch(() => {
      });
  }, []);

  const getPageNumber = () => {
    let pageNumber;

    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      pageNumber = urlParams.get("page");
    }

    if (!pageNumber) {
      pageNumber = 1;
    }

    pageNumber = pageNumber * 1;

    return pageNumber;
  };

  useEffect(() => {
    setCurrentPage(getPageNumber());

    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    setCurrentPage(getPageNumber());
  }, [location]);

  useEffect(() => {
    if (!currentPage) {
      return;
    }

    getDashboard();

    const getSubscribe = async () => {
      await subscribe();
    };

    getSubscribe()
      .then(() => {
      })
      .catch(() => {
      });
  }, [currentPage, subscribe, getDashboard]);

  const setAutoPublish = (e, isAutoPublish) => {
    e.preventDefault();

    dashboard.setAutoPublish(isAutoPublish)
      .then(({status}) => {
        if (status === 200) {
          getDashboard();
        } else {
          alert("An error occured! Please try again.");
        }
      });
  };

  const authenticate = (e) => {
    e.preventDefault();

    github.authenticate()
      .then(({data}) => {
        const {url} = data;

        if (typeof url === "undefined") {
          alert("Error! Please try again.");

        } else {
          window.location.href = url;
        }
      });
  };

  const deploy = (branch) => {
    dashboard.deploy(branch)
      .then(() => {
        getDashboard();
      })
      .catch((error) => {
        console.error(error);
      })
  };

  const hasData = pageData !== null;

  const shop = pageData?.shop;
  const configuration = pageData?.configuration;
  const deployments = pageData?.deployments;
  const pages = pageData?.pages;

  return (
    <Layout>
      <SEO
        title={"Theme Deploy App"}
        description={"Deployments dashboard"}
        fullTitle={true}
        path={path}/>
      <PageData hasData={hasData}>
        {hasData && <StyledTitle>Deployments for <b>{shop}</b></StyledTitle>}
        {hasData && <>
          <Summary setAutoPublish={setAutoPublish}
                   {...configuration} />
          {(!configuration) && <Login authenticate={authenticate}/>}
          {(deployments && deployments.length > 0) && <Deployments deployments={deployments} deploy={deploy}/>}
          {(pages && pages > 1) && <Pagination pagesCount={pages} current={currentPage}/>}
        </>
        }
      </PageData>
    </Layout>
  );
};

function index(props) {
  return <PrivateRoute component={IndexPage} {...props}/>
}

export default index;
