import React, {useEffect, useState} from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import {Link} from "gatsby";
import ExternalLink from "../Core/ExternalLink";
import {getFormattedDate, getStatusDisplayText, getStatusColor} from "../../services/deployment";
import viewImage from "../../resources/img/icons/view.svg";
import viewActiveImage from "../../resources/img/icons/view-active.svg";
import syncImage from "../../resources/img/icons/sync.svg";
import syncActiveImage from "../../resources/img/icons/sync-active.svg";
import loaderImage from "../../resources/img/loader/loader.gif";
import breakpoints from "../../styles/breakpoints";
import Status from "../Core/Deployment/Status";
import ThemePreviewUrl from "../Core/Deployment/ThemePreviewUrl";

const DeploymentContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  position: relative;
  border-bottom: ${props => props.last ? 0 : `1px solid ${colors.greyDark}`};
`;

const DeploymentContent = styled.div`
  ${fonts.montserratRegular};
  display: flex;
  justify-content: space-between;
  min-height: 72px;
  flex-direction: column;
  align-items: flex-start;

  @media(min-width: ${breakpoints.xl}) {
    flex-direction: row;
    align-items: center;
    
    > div:first-of-type {
      padding-left: 24px;
    }
  }
`;

const Border = styled.span`
  display: none;
  position: absolute;
  height: 72px;
  width: 4px;
  background: ${props => props.color};
  left: 0;
  top: calc(50% - 36px);
  
  @media(min-width: ${breakpoints.xl}) {
    display: block;
  }
`;

const DeploymentTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  min-width: 320px;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
`;

const Commit = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8px;
  max-width: 280px;
`;

const DeploymentStatus = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
`;

const Trigger = styled.div`
  margin-left: 12px;
`;

const BranchRef = styled.span`
  ${fonts.montserratBold};
`;

const DeploymentLink = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  
  @media(min-width: ${breakpoints.xl}) {
    padding: 0 24px;
    width: auto;
  }
`;

const DeploymentActions = styled.div`
  display: none;
  
  @media(min-width: ${breakpoints.xl}) {
    display: flex;
    align-items: center;
  }
`;

const DeploymentTime = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
`;

const Time = styled.div`
  min-width: 240px;
  
  p {
    &:first-of-type {
      margin-bottom: 8px;
    }
    
    strong {
      ${fonts.montserratMedium};
    }
  }
`;

const ActionViewContainer = styled.div`
  width: 36px;
  height: 36px;
`;

const ActionView= styled(Link)`
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  
  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }
`;

const ActionDeploy = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: url(${syncImage}) no-repeat;
  
  &:hover {
    background: url(${syncActiveImage}) no-repeat;
  }
  
  background-size: 36px 36px;
  background-position: 50%;
`;

const LoadingImage = styled.div`
  outline: none;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  
  img {
    width: 26px;
    height: 26px;
    object-fit: contain;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80px;
`;

const Deployment = ({deployment, deploy, num}) => {
  const isCancelled = React.useRef(false);
  const [isDeploying, setIsDeploying] = useState(false);
  const [isViewHover, setIsViewHover] = useState(false);

  const {status, trigger, commitId, commitUrl, commitMessage, deploymentTime, previewUrl, logId} = deployment;

  const statusColor = getStatusColor(deployment);
  const statusText = getStatusDisplayText(deployment);
  const formattedDate = getFormattedDate(deployment);

  useEffect(() => {
    return () => {
      isCancelled.current = true;
    };
  }, []);

  const deployBranch = async (e, branch) => {
    e.preventDefault();

    setIsDeploying(true);

    setTimeout(function () {
      if (isCancelled.current) {
        return;
      }

      deploy(branch);

      setIsDeploying(false);
    }, 1000);
  };

  const viewHover = (e) => {
    e.preventDefault();

    setIsViewHover(!isViewHover);
  };

  const showLogUrl = logId && (status === "completed" || status === "failed");
  const deployInProgress = (status === 'in_progress' || isDeploying);

  return (
    <DeploymentContainer last={(num + 1) % 8 === 0}>
      <Border color={statusColor}/>
      <DeploymentContent>
        <DeploymentTitle>
          <Title>
            <div>
              <BranchRef>{deployment['ref']}</BranchRef>@
            </div>
            <ExternalLink href={commitUrl}>
              {commitId}
            </ExternalLink>
          </Title>
          {commitMessage &&
          <Commit>
            {commitMessage}
          </Commit>}
          <DeploymentStatus>
            <Status color={statusColor}>
              {statusText}
            </Status>
            <Trigger>
              {trigger === "webhook" && `Triggered by github commit`}
            </Trigger>
          </DeploymentStatus>
        </DeploymentTitle>
        <DeploymentLink>
          <ThemePreviewUrl previewUrl={previewUrl} status={status} />
        </DeploymentLink>
        <DeploymentTime>
          <Time>
            {formattedDate && <p>{formattedDate}</p>}
            {(status === "completed" && deploymentTime) && <p>Deployed in <strong>{deploymentTime}</strong></p>}
            {(status === "failed" && deploymentTime) && <p>Deployment time <strong>{deploymentTime}</strong></p>}
          </Time>
        </DeploymentTime>
        <DeploymentActions>
          <ActionButtons>
            {deployInProgress ? <LoadingImage><img src={loaderImage} alt={"Deploying..."}/></LoadingImage> :
              <ActionDeploy onClick={(e) => deployBranch(e, deployment['ref'])}/>}
            {showLogUrl &&
            <ActionViewContainer onMouseEnter={(e) => viewHover(e)}
                                onMouseLeave={(e) => viewHover(e)}>
              <ActionView to={`/log/${logId}`}>
                {isViewHover ? <img src={viewActiveImage} alt={"Deployment Log"}/> :
                  <img src={viewImage} alt={"Deployment Log"}/>}
              </ActionView>
            </ActionViewContainer>}
          </ActionButtons>
        </DeploymentActions>
      </DeploymentContent>
    </DeploymentContainer>
  );
};

export default Deployment;
