import React from "react"
import styled from "styled-components"
import ButtonWhite from "../Core/ButtonWhite";
import {Link} from "gatsby";
import deploymentActive from "../../resources/img/icons/deploy-active.svg";
import deploymentInactive from "../../resources/img/icons/deploy-inactive.svg";
import breakpoints from "../../styles/breakpoints";
import ButtonGreen from "../Core/ButtonGreen";

const SummaryContainer = styled.div`
`;

const DeploymentStatus = styled.div`
  background-image: url(${({active}) => active ? deploymentActive : deploymentInactive});
  background-repeat: no-repeat;
  background-position: left center;
  display: inline-block;
  padding-left: 22px;
  line-height: 18px;
  margin-bottom: 12px;
`;

const Configuration = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  
  @media(min-width: ${breakpoints.md}) {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
`;

const Actions = styled.div`
`;

const Status = styled.div`
  text-align: left;
  margin-top: 24px;
  
  a {
    margin-top: 12px;
    display: block;
  }
  
  @media(min-width: ${breakpoints.md}) {
    margin-top: 0;
    text-align: right;
  }
`;

const Summary = ({isActive, isAutoPublish, repository, setAutoPublish}) => {
  return (
    <SummaryContainer>
      <Configuration>
        <Actions>
          {isActive &&
          <ButtonWhite onClick={(e) => setAutoPublish(e, !isAutoPublish)}>
            {(isAutoPublish) ? "Stop autopublishing" : "Start autopublishing"}
          </ButtonWhite>}
        </Actions>

        <Status>
          <DeploymentStatus active={isActive}>
            Deployments are {(isActive) ? 'active' : 'inactive' }
          </DeploymentStatus>
          {repository && <p><b>{repository}</b></p>}
          <Link to={"/github/authenticate"}>
            Link to another repository
          </Link>
        </Status>
      </Configuration>
    </SummaryContainer>
  );
};

export default Summary;
