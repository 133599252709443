import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import Deployment from "./Deployment";
import breakpoints from "../../styles/breakpoints";

const DeploymentsContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 20px 32px;
  background: ${colors.white};
  border: 1px solid ${colors.greyDark};
  border-radius: 8px;
  
  @media(min-width: ${breakpoints.xl}) {
    padding: 40px 64px;
  }
`;

// const Headers = styled.div`
//   ${fonts.montserratMedium};
//   width: 100%;
//   padding: 24px 0;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   border-bottom: 1px solid ${colors.greyDark};
//   font-size: 14px;
//   line-height: 18px;
//
//   > div {
//     &:nth-of-type(1) {
//       padding-left: 24px;
//       flex-basis: 75%;
//     }
//
//     &:nth-of-type(2) {
//       flex-basis: 25%;
//     }
//   }
// `;

const Deployments = ({deployments, deploy}) => {
  return (
    <DeploymentsContainer>
      {/*<Headers>*/}
      {/*  <div>*/}
      {/*    Deployment*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    Updated*/}
      {/*  </div>*/}
      {/*</Headers>*/}
      {deployments.map((deployment, index) => {
        return (
          <Deployment key={index} deployment={deployment} deploy={deploy} num={index}/>
        )
      })}
    </DeploymentsContainer>
  );
};

export default Deployments;
